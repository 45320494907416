@import url('../../../../colors.css');

.component-investisseur {
    background: var(--quinaryColor);
}
.content-title-page-tb-investisseur {
    width: 100%;
    z-index: 999;
}
.titre-repartition-detail {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: var(--secondary-color);

}
.text-progress-investisseur {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.6);
}
.width-bloc {
    width: 60%;
}
.repartition-pret {
    padding: 50px;
}