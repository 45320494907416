@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
.component-front-register-infos-perso-three {
  padding: 200px;
  background: var(--quinaryColor);
  border-radius: 10px;
}

.card-photo-identite{
  background-color: var(--primaryColor);
  padding: 50px 10px;
}


.bloc-div-register-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  margin: 0 20px;
}
.style-checkbox {
  background: var(--primaryColor);
  border: 0.25px solid #115C67 !important;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  align-items: center;
}


.style-chckbox, .style-radio{
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-color: var(--primaryColor) !important;
  box-sizing: border-box !important;
  cursor: pointer;
  outline: none !important;
}

.style-chckbox:checked, .style-radio:checked{
  background-color: #115C67 !important;
}

.style-chckbox:before{
  font: 12pt 'FontAwesome';
  content: '\f00c';
  display: inline;
  padding-left: 1px;
  margin-right: 0.5em;
  color: var(--primaryColor);
}

.style-radio{
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-color: var(--primaryColor) !important;
  box-sizing: border-box !important;
  cursor: pointer;
  outline: none !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 4px;
  border: 1px solid #115C67;
}

.style-radio:before{
  font: 12pt 'FontAwesome';
  content: '\f00c';
  display: inline;
  padding-left: 1px;
  color: var(--primaryColor);
}



.color-second-part {
  color: yellow !important;
}

.div-upload {
  background: var(--primaryColor) !important;
  border: 0.25px solid rgba(187, 68, 17, 0.5) !important;
  box-sizing: border-box !important;
  border-radius: 0 !important;
  width: 100%;
}

.component-front-register-infos-perso-three
  .rs-uploader-text
  .rs-uploader-trigger:not(.rs-uploader-trigger-disabled)
  .rs-uploader-trigger-btn,
.rs-uploader-picture-text
  .rs-uploader-trigger:not(.rs-uploader-trigger-disabled)
  .rs-uploader-trigger-btn {
  background: none !important;
  border: none !important;
  box-sizing: border-box !important;
  border-radius: 0 !important;
}
.btn-theme-inscription {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.025em;
  color: var(--primaryColor);
  background: #115c67;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  white-space: nowrap;
  outline: none;
}
.btn-theme-inscription:hover {
  color: #115c67;
  border: 1px solid #115c67;
  background-color: var(--primaryColor);
  text-decoration: none;
}

.start-label{
  width: 100%;
}


.div-contenant{
  width: 70%;
  margin: auto;
  padding: 10px;
}
.boutton-next{
  margin: 0 20px;
  width: 136px;
  height: 57px;
  background: #115C67;
  border-radius: 10px;  

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--primaryColor);
}
.boutton-next:hover{
  
  background: var(--primaryColor);
  color: #115C67;
  border: 1px solid #115C67;
}

.titre-password{
  text-align: start !important;
  margin: 0 20px;
}

