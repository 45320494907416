@import url('../../../colors.css');

.btnidentite {
  background: #115c67;
  color: hsl(0, 0%, 100%);
}
.votreidentite {
  background: #e9f3f4;
  margin-bottom: -21px;
}
.contentpersonnel {
  margin-top: 0;
  background: white;
}
.btnsave:hover {
  color: #115c67;
  text-decoration: none;
  background: var(--primaryColor);
}
.btnsave {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background: #115c67;
  border-radius: 10px;
  color: var(--primaryColor);
  padding: 18px 23px !important;
  border: 1px solid #115c67;
}
.modal-de-soumission-containers {
  background: rgba(rgb(253, 253, 253) 0.8);
}
.btn-theme-annuler {
  background: var(--primaryColor);
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #115c67;
  padding: 1rem !important;
  width: 100%;
  border-radius: 10px;
}
.btn-theme-annuler:hover {
  text-decoration: none;
  color: var(--primaryColor) !important;
  border: 1px solid #115c67;
  background: #115c67;
}
.titreinfoperson {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 42px;
  letter-spacing: -0.025em;
  color: var(--primarytextColor);
}
.btnformedits2 {
  display: flex;
  justify-content: left;
}
.civilite {
  margin-top: 16px !important;
  margin-right: 3rem !important;
  color: var(--primarytextColor);
}
