.navbar-vertical-customisation-v2 {
	padding: 3rem 0 2rem 0;
	height: 100%;
	min-height: 1000px;
	max-width: 17% !important;
	min-width: 17%;
	width: border-box;
	background-color: var(--primaryColor)  !important;
	position: fixed;
	left: 0;
	right: 0;
	top: 70px;
	z-index: 999;
}

.nav-items-customs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item-vertical-custom {
  width: 100%;
  padding: 2rem 0 0 0;
}

.nav-link-vertival-custom {
	font-family: "Poppins" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 18px !important;
	color: var(--quinaryTextColor);
	width: 100%;
	display: flex !important;
	gap: 10px;
	align-items: center;
	padding: 1rem 5% 1rem 18% !important;
}

/* .nav-link-vertival-custom:hover,
.nav-link-vertival-custom:focus,
.nav-link-vertival-custom.actived {
  background-color: #1955A8 !important;
  color: var(--primaryColor)   !important;
  font-weight: 600 !important;
  transition: 0.2s ease;
} */


.navbar-vertical-container-icon {
  width: 30px !important;
  background-color: var(--primaryColor) ;
  text-align: center;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-size: 14px;
  color: var(--quinaryTextColor);
  border-radius: 12px;
}

.text-dashbord {
  color: var(--quinaryTextColor) !important;
}

/* .nav-link-vertival-custom:hover .text-dashbord,
.nav-link-vertival-custom:focus .text-dashbord,
.nav-link-vertival-custom.actived .text-dashbord {
  color: var(--primaryColor)   !important;
} */

/* .nav-link-vertival-custom:hover .navbar-vertical-container-icon,
.nav-link-vertival-custom:focus .navbar-vertical-container-icon,
.nav-link-vertival-custom.actived .navbar-vertical-container-icon {
  color: var(--primaryColor)   !important;
  background-color: #1955A8 !important;
} */

#scrollbar {
  height: 100%;
  overflow-y: auto;
}
#scrollbar::-webkit-scrollbar {
  display: none;
}
.contents {
  height: 0px !important;
}

.nav-items-customs .active, .nav-items-customs .active .navbar-vertical-container-icon, .nav-items-customs .active .text-dashbord{
  /* color: var(--primaryColor)   !important;
  background-color: #1955A8 !important; */
}
