@import url('../../../colors.css');
.component-front-home {
	background-color: var(--primaryColor) !important;
}
.bloc-div-chiffre {
	background: var(--primaryColor);
	/* border: 1px solid #bb4411; */
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 1rem;
}
.icon-chiffre {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 48px;
	line-height: 62px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: var(--primaryBorderColor);
}
.texte-chiffre {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: var(--nonaryTextColor);
}
.div-bloc-a-propos {
	position: relative;
	width: 100%;
}
.bloc-icon-a-propos {
	position: absolute;
	padding: 3rem 0;
}
.bloc-image-a-propos {
	width: 100%;
}
.titre-a-propos-text {
	font-family: "Poppins";
	font-weight: 700;
	font-size: 55px;
	color: var(--secondary-color);
}
.sous-titre-ap-propos-text {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 19px;
	line-height: 10px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}
.text-ap-propos-text {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	text-align: justify;
}
.rs-progress-circle-info {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 50px;
	line-height: 75px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}
.text-progress-bar {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}
.bloc-info-team {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.text-info-team {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 15px;
	letter-spacing: -0.025em;
	/* color: gray; */
}
.team-item-social-container {
	display: flex;
	justify-content: space-between;
	width: 23%;
}
.titre-info-team {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 10px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}
.cards {
	background: var(--tertiaryColor);
	padding: 25px;
}
.titre {
	font-family: "Poppins";
	font-size: "normal";
	font-weight: 700;
	line-height: 75px;
	letter-spacing: -2.5%;
	color: var(--primarytextColor) 70%;
	margin-left: 200px;
}
.titre {
	font-family: "Poppins";
	font-size: "normal";
	font-weight: 700;
	line-height: 75px;
	letter-spacing: -2.5%;
	color: var(--primarytextColor) 70%;
}

.circulaire {
	margin-top: 150px;
}
.card-back h4 {
	font-family: "Poppins";
	font-weight: 400;

	font-style: normal;
	line-height: 30px;
	letter-spacing: -2.5%;
	margin-top: 40px;
}
.team {
	margin: 100px;
	background-color: var(--primaryColor);
}

.img-cov {
	width: 900px;
}
/* circ */
.flex {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

@media (max-width: 991.98px) {
	.padding {
		padding: 1.5rem;
	}
}

@media (max-width: 767.98px) {
	.padding {
		padding: 1rem;
	}
}

.padding {
	padding: 5rem;
}

.progress.progress-md {
	height: 8px;
}
.template-demo .progress {
	margin-top: 1.5rem;
}

.progress {
	border-radius: 3px;
	height: 8px;
}

.pl-2,
.px-2,
.template-demo .circle-progress-block {
	padding-left: 0.5rem !important;
}

.pr-2,
.px-2,
.template-demo .circle-progress-block {
	padding-right: 0.5rem !important;
}

.mb-3,
.template-demo .circle-progress-block,
.my-3 {
	margin-bottom: 1rem !important;
}
.vjs-poster {
	border-radius: 20px;
}
.site-partenaire {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}
.propos-sous-titre {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 45px;
	letter-spacing: -0.025em;
	color: var(--quaternaryTextColor);
}
.dessin-container {
	display: flex;
	justify-content: center;
}
.img-part{
	max-width: 50%;
}