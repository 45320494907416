.data-center-header {
    margin-left: 23.5px;
  }
  .data-center-title {
    display: flex;
    align-items: center;
  }

  .data-center-head h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: var(--primarytextColor);
    text-align: left;
    position: relative;
    right: 1%;
  }
  
  .data-center-head p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: -0.025em;
    color: #2D2D2D;
    text-align: left;
    margin-left: 25px;
  }
  
  .data-center-title {
    margin-bottom: -1rem;
  }
  
  .data-center-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 25px;
    margin-top: 48px;
    margin-bottom: 134px;
  }
  
  .data-center-body > .details > p {
    /*font-family: Poppins;*/
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
  
    /* identical to box height */
    color: var(--primarytextColor);
  }
  
  .data-center-body > .details > p > span {
    /*font-family: Poppins;*/
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
  
    color: var(--primarytextColor);
  }
  
  .progressBar > p {
    /*font-family: Poppins;*/
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    color: var(--primarytextColor);
  }
  
  .progress {
    position: relative;
    
  }
  
  .progressBarChild {
    position: relative;
  }
  
  .progressBarChild > p {
    display: flex;
    gap: 0;
  }
  
  .progressBarChild > p > span:nth-child(1) {
    padding-right: 65px;
    /*font-family: Poppins;*/
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height */
    display: flex;
    align-items: center;
  
    color: #757575;
  }
  
  .progressBarChild > p > span:nth-child(2) {
    /*font-family: Poppins;*/
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height */
  
    display: flex;
    align-items: center;
  
    /* bleu */
  
    color: #115c67;
    position: relative;
    right: 4%;
  }
  
  .objectif {
    /*font-family: Poppins;*/
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height */
  
    display: flex;
    align-items: center;
  
    color: #757575;
    padding-left: 95px;
  }
  
  .data-center-table {
    margin-right: 40% !important;
    padding-bottom: 5rem;

  }
  
  .liste-des-donnateurs {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    /* identical to box height */
  
    color: var(--primarytextColor);
  }
  
  .info-donnateur {
    border-top: #979595 solid 1px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }
  
  .userData {
    display: flex;
    padding: initial 0 !important;
    width: 75%;
  }
  
  .userData img {
    width: 55px;
    height: 55px;
    border-radius: 100%;
    margin: 0;
  }
  
  .userData p {
    display: flex;
    flex-direction: column;
    padding-left: 22px;
    padding-top: 14px;
  }
  
  .user-data p > span:nth-child(1) {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    /* identical to box height, or 118% */
  
    letter-spacing: 0.2px;
  
    /* black */
  
    color: #171d33;
    text-align: left !important;
  }
  
  .user-data p > span:nth-child(2) {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 154% */
  
    letter-spacing: 0.2px;
  
    color: rgba(0, 0, 0, 0.7);
    text-align: left !important;
  }
  
  .price {
  }

  .price p {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold !important;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 118% */
  
    letter-spacing: 0.2px;
  
    /* black */
  
    color: #171d33;
  }
  
  .price p {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    margin-top: 24px;
    white-space: nowrap;
  }
  