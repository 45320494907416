@import url('../../../colors.css');

body{
	padding: 0;
	margin: 0 !important;
}
  
html{
	width: 100%;
	height: 100%;
}
.component-front-register {
	padding: 100px;
	background: rgba(245, 245, 245, 255);
	border-radius: 10px;
}

.inscription{
	/* height: 130vh; */
	background: var(--septanaryColor);
}
  
.inscription .divLogo{
	background: var(--secondary-color);
	border-radius: 14px;
}

.inscription .auth-form-container {
  background: var(--primaryColor);
  border-radius: 14px;
}
.container-secondaire{
	background: var(--primaryColor);
}
.color-part1 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 17px;
	color: var(--nonaryTextColor);
}

.color-part2 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 17px;
	text-align: center;
	color: var(--secondary-color);
}

.color-part2:hover {
	color: var(--secondary-color);
}
.btn-inscription {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.025em;
	color: var(--primaryColor);
	background: var(--secondary-color);
	box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
	border-radius: 10px;
	padding: 12px;
}
.btn-inscription:hover {
	text-decoration: none;
	color: var(--secondary-color);
	border: 1px solid var(--secondary-color);
	background: var(--primaryColor);
}
.position-icon-identifiant {
	position: absolute;
	margin-bottom: 5px;
}
/* .color-icon {
	color: #c4c4c4;
} */

.btn-theme-inscription {
	background: var(--secondary-color);
	font-family: "DM Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 17px;
	text-align: center;
	color: var(--primaryColor);
	padding: 1rem !important;
	width: 100%;
	border-radius: 10px;
	outline: none !important;
}

.auth-checkbox-group {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
}
input.auth-checkbox-input {
	width: 25px;
	height: 25px;
	padding-top: 2px !important;
	cursor: pointer;
}
input.auth-checkbox-input:before {
	font: 14pt "FontAwesome";
	content: "\f00c";
	display: inline;
	padding-left: 4px;
	color: var(--primaryColor);
}
.auth-checkbox-input:checked {
	background-color: var(--secondary-color); 
  }
.auth-checkbox-input-container {
	padding-right: 15px;
}

.auth-checkbox-label-container {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 17px;
	color: var(--nonaryTextColor);
}
.auth-link {
	color: var(--secondary-color) !important;
	font-weight: 600;
}
.auth-greening {
	color: var(--secondary-color) !important;
	font-weight: 600;
}

.auth-darking {
	color: var(--primarytextColor) !important;
	font-weight: 600;
}

.react-tel-input .flag-dropdown.open {
	z-index: 9999 !important;
}

.password-validation-terms-row {
  border-bottom: none !important;
}
.password-validation-terms-title {
  font-size: 16px;
  color: var(--secondary-color) !important;
}
.min-character-uppercase-ok,
.min-character-lowercase-ok,
.min-character-number-ok,
.min-character-count-ok,
.min-character-special-ok {
  color: var(--secondary-color) !important;
  font-weight: 700;
}
.phonePadding{
	margin-bottom: 23px !important;
	border: none;
	/* border-bottom: 1px solid var(--septanaryBorderColor); */
}
.react-tel-input .form-control{
	border: none !important;
	border-bottom: 1px solid var(--septanaryBorderColor) !important;
	border-radius: 0 !important;
}

.react-tel-input .selected-flag, .react-tel-input .flag-dropdown{
	border: none !important;
	background: transparent !important;
}

.unMargin{
	padding-left: 6% !important;
}

.inscription .form-control{
	border: none;
	border-bottom: 1px solid var(--septanaryBorderColor);
}