@import url("../../../colors.css");

.editprofilebackground {
  height: auto;
}
.editcontent {
  background: white !important;
}
.imgedit {
  display: flex;
  width: 180px;
  height: 180px;
  margin: 20px;
  background: white;
}
.imgeditcontent {
  display: flex;
  justify-content: center;
}
.inputformedit {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 10px;
  text-align: start;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid #e0e0e0 !important;
  border-left: none;
  border-top: none;
  border-right: none;
  margin: 3px;
  width: 100%;
}
.choix {
  border: 1px solid #115c67;
  padding: 10px;
  margin: 4px;
}
.btnformedit {
  width: 109px;
  height: 57px;
  left: 1224px;
  float: right;
  margin-top: 190px;
  /* bleu */
  margin-right: 6px;
  background: #115c67;
  border-radius: 10px;
  color: var(--primaryColor);
}
.nav > li a {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 26px;
  color: var(--quinaryTextColor) !important;
}
.tab-pane .fade .in .active .row {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: var(--primarytextColor);
}
