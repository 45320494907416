.component-home-banner {
  background-image: url(../../../assets/images/unsplash_wYTd-B7BdoQ.png);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}
.close {
  font-size: 32px !important;
  margin-right: 8px !important;
  color: #1955A8;
  box-shadow: hidden !important;
}
.close:hover {
  color: white !important;
}
.ips {
  position: absolute;
  left: 300px;
  height: 500px;
  top: 300px;
  z-index: 199;
}
.customModal {
  width: 100%;
  height: 400px;
  position: absolute;
  top: 100px;
}
.finance {
  padding-left: 3rem !important;
  padding-right: 3rem;
}
/* .bloc-banner-1 {
  background: rgba(230, 110, 59, 0.28);
  border-radius: 10px;
  width: 276.36px;
  height: 223.68px;
  position: absolute;
  top: 157px;
  left: 13%;
  z-index: 1;
}
.bloc-banner-2 {
  background: rgba(230, 110, 59, 0.28);
  border-radius: 10px;
  width: 172.49px;
  height: 139.61px;
  position: absolute;
  top: 75px;
  right: 19%;
  z-index: 11;
}
.bloc-banner-3 {
  background: rgba(230, 110, 59, 0.28);
  border-radius: 10px;
  width: 101.24px;
  height: 81.94px;
  position: absolute;
  top: 604px;
  right: 45%;
  z-index: 1;
} */
.bloc-banner-text {
  flex-wrap: wrap;
  z-index: 99999;
  position: absolute;
  left: 20%;
  top: 140px;
}
.titre-banner {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 140%;
  color: var(--primaryColor);
}
.bloc-banner-btn {
  margin-top: -220px;
  left: -12px;
  z-index: 99;
}
.btn-investir-banner {
  background: #1955A8;
  border-radius: 4px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 175%;
  text-align: center;
  color: var(--primaryColor);
  padding: 1rem !important;

  border: 1px solid #1955A8 !important;
}
.btn-investir-banner:hover {
  color: #1955A8;
  background-color: var(--primaryColor);
  border: 1px solid #1955A8;
  text-decoration: none;
}
.btn-submit-banner {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  background: transparent;
  border-radius: 4px;
  padding: 1rem !important;
  border: 1px solid white !important;
  box-sizing: border-box;
  color: var(--primaryColor);
}
.text-light {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: var(--primaryColor);
}

.btn-submit-banner:hover {
  color: var(--primaryColor) !important;
  background-color: #1955A8 !important;
  border: none !important;
  text-decoration: none;
}
.bloc-banner-right-1 {
  border-radius: 10px;
  width: 308px;
  height: 309px;
  background-color: #e66e3b;
  position: absolute;
  top: 357px;
  left: 17%;
  z-index: 11;
}
.bloc-banner-right-2 {
  background: linear-gradient(
      0.2deg,
      var(--primarytextColor) 0.18%,
      rgba(255, 255, 255, 0) 101.72%
    ),
    #c4c4c4;
  border-radius: 10px;
  width: 563px;
  height: 556px;
  position: absolute;
  top: 80px;
  left: 20%;
  z-index: 9999;
}
.bloc-banner-right-3 {
  background: var(--primaryColor);
  border-radius: 10px;
  width: 174px;
  height: 174px;
  position: absolute;
  top: 490px;
  left: 62%;
  z-index: 1;
}
.thumbs-wrapper.axis-vertical {
  display: none;
}
p.carousel-status {
  display: none;
}
.carousel .control-next.control-arrow:before {
  border-left: none !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: none !important;
}
.btn-see-more {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.5rem;
}
.btn-see-more:hover {
  background-color: var(--primaryColor);
  color: #000;
  border: 1px solid #000;
  text-decoration: none;
}
.title-legend {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 38px;
  letter-spacing: -0.025em;
  color: var(--primaryColor);
}
.text-legend {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.025em;
  color: #dedede;
}
.carousel .slide .legend {
  text-align: left !important;
  background-color: transparent !important;
  padding: 30px !important;
  transition: all 0.5s ease-in-out;
  transition-property: none !important;
}
.bloc-banniere-v2 {
  padding-top: 11%;
}

.couverture-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 140%;
  color: var(--primaryColor);
  text-transform: uppercase;
  width: 60%;
}
.container.couverture-container {
  padding: 50px;
}
.couverture-description-container, 
.couverture-description-container p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  /* width: 60%; */
  color: var(--primaryColor);
  text-align: left;
}
.couverture-content-container {
  margin-bottom: 50px;
  padding-top: 5%;
}
.couverture-actions-btn-container {
  display: flex;
  justify-content: flex-start;
  width: 60%;
}
.marge-container-description-accueil {
    margin-top: 3%;
}
.funding-btn-container {
  padding-right: 40px;
}
.funding-btn {
  background: #1955A8 !important;
  border-color: #1955A8 !important;
  padding: 9px 30px !important;
  border-radius: 4px !important;
  font-family: DM Sans !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 175% !important;
  color: var(--primaryColor) !important;
  outline: none !important;
}
.funding-video-btn {
  padding: 9px 30px !important;
  background: var(--primaryColor);
  border-radius: 4px !important;
  font-family: DM Sans !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 175% !important;
  color: #1955A8 !important;
  outline: none !important;
}
.funding-btn:hover {
  color: #1955A8 !important;
  background: var(--primaryColor) !important;
}
.funding-video-btn:hover {
  background: #115C67 !important;
  color: var(--primaryColor) !important;
}

/**************** banner-icon-down-container *************/
.banner-icon-down-container{
  position: absolute;
  width: 100%;
  bottom: 5px;
}

.banner-icon-container{
  width: 100%;
  display: flex;
  justify-content: center;
}
.text-bold {
    font-weight: bold;
}
.text-et-banniere {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 46px !important;
    line-height: 140%;
    text-transform: uppercase;
    color: var(--primaryColor);
}
.text-small-banniere {
    font-size: 13px !important;
}