.child-table-container {
	background-color: white;
}

.table-actions-container {
	display: flex;
	align-items: center;
}

.progress-bar-projet {
	height: 4px !important;
}

.statut-percentage {
	color: #1955A8;
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.number-chat {
	position: absolute;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 8px;
	line-height: 8px;
	color: var(--primaryColor);
	background-color: #e53e3e;
	border-radius: 12px;
	padding: 2px 3px;
	left: 12px;
	top: -2px;
	border: 1px solid var(--primaryColor);
}

.number-chat-link {
	position: relative;
	text-decoration: none;
}

.filtres-container {
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 3rem;
}

.filter-content{
	display: flex;
	align-items: center;
	gap: 15px;
	flex-wrap: wrap-reverse;
}

.filter-label {
	font-family: "Poppins";
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	color: var(--primarytextColor);
}

.filter-input {
	width: 162px !important;
	height: 39px !important;
	background: var(--quaternary-color) !important;
	border-radius: 10px !important;
	outline: none;
	border: 1px solid var(--quaternary-color);
	font-family: Poppins;
	font-style: normal;
	font-weight: 200;
	font-size: 14px;
	line-height: 21px;
	color: var(--primarytextColor);
}

.filter-input-select-group {
	position: relative;
	cursor: pointer;
}

.input-select {
	width: 167px !important;
	padding: 5px 7px;
	height: 39px !important;
	background: var(--quaternary-color) !important;
	border-radius: 10px !important;
	outline: none;
	border: 1px solid var(--quaternary-color);
	font-family: "Poppins";
	font-style: normal;
	font-weight: 200;
	font-size: 14px;
	line-height: 21px;
	color: var(--primarytextColor) !important;
	cursor: pointer;
}

.input-select-icon {
	font-size: 35px !important;
	position: absolute;
	right: 0;
	top:2px;
	color: #7B7F84;
}
.table>tbody>tr>td {
    padding: 16px !important;
	z-index: 0 !important;
}
.table>thead>tr>th {
    border-bottom: 0px solid #ddd !important;
	z-index: 0 !important;
}

@media screen and (max-width:839px) {
	.filtres-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.filter-content{
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
	
}


.redirection-projet {
	font-weight:normal;
	font-size: 14;
	color: #2d3748;
	font-family: "Poppins";
}

.redirection-projet:hover {
	text-decoration: none;
	color: #115c67;
	font-weight: 500;
}
.dropdown-toggle::after {
	visibility: hidden !important;
}

.dropdown-toggle{
	box-shadow: none !important;
	border: none !important;
	
}

.theDrop svg{
	font-size: 20px !important;
}
