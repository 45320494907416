
.component-front-register-infos-perso-two{
  background: var(--quinaryColor);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-telecharger-document{
  background: var(--primaryColor) !important;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07);
  height: 80vh;
  max-width: 70vw;
  display: flex;
  justify-content: center;
  align-items: center;
}


.style-prev{
font-family: 'DM Sans';
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 17px;
color: rgba(0, 0, 0, 0.5);
}
.style-next{
font-family: 'DM Sans';
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 17px;
color: rgba(0, 0, 0, 0.5);
}


.title-verification{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 42px;
  color: var(--primarytextColor);
}

.check-input1{
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 5px;
  width: 20px;
  height: 20px;
}
.check-input1:checked{
  
  background: #115C67 !important;
}

.check-label1{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
}

.boutton-continuer{
  background: #115C67 !important;
  border-color: #115C67 !important;
  padding: 9px 30px !important;
  border-radius: 10px !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 175% !important;
  font-size: 18px !important;
  color: var(--primaryColor) !important;
  outline: none !important;
}
.boutton-continuer:hover{
  background: var(--primaryColor) !important;
  color: #115C67 !important;
  border: 1px solid #115C67 !important;
  text-decoration: none;

}

.contenant-2{
  width: 100%;
  margin: auto;
}

.btn-continuer-2{
  margin-left: 60px;
}

.titre-document{
  text-align: start !important;
  font-size: 22px !important;
}

.boutton-continuer {
  background: #115C67 !important;
  border-color: #115C67 !important;
  padding: 9px 20px !important;
  border-radius: 8px !important;
  font-family: DM Sans !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 175% !important;
  color: var(--primaryColor) !important;
  text-transform: uppercase;
}
