.titreposte {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.6);
}
.btnrepresentant:hover {
	text-decoration: none !important;
	background: #115c67 !important;
  color: var(--primaryColor);
}

.auth-form-container input:checked {
	background-color: #115c67 !important;
}
.btnrepresentant {
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px;
	letter-spacing: -0.025em;
	text-align: center;
	color: #115c67;
	background: var(--primaryColor);
	border: 0.25px solid #115c67;
	box-sizing: border-box;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
  padding: 2rem 4rem;
  margin: 5rem 0 !important;
}
.tab-pane {
	background-color: white;
}
.civilite {
	margin-top: 16px !important;
	margin-right: 3rem !important;
}
.inputdirection::before {
	color: #115c67 !important;
	border-color: var(--primaryColor) !important;
	width: 12px;
	height: 12px;

	background-color: #115c67 !important;
}
.inputdirection::after {
	color: #115c67 !important;
	border-color: var(--primaryColor) !important;
	width: 12px;
	height: 12px;

	background-color: #115c67 !important;
}

.btnsave {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	background: #115c67;
	border-radius: 10px;
	color: var(--primaryColor);
	padding: 18px 23px !important;
}
.btnsave :hover {
	padding: 15px 10px;
	color: #115c67;
	border: 1px solid #115c67;
	text-decoration: none;
	background: var(--primaryColor);
}
.inputdirection {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.6);
}
