@import url('../../../colors.css');

.redirection-projet {
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 0;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
    color: var(--tertiaryTextColor) !important;
}
.redirection-projet:hover {
	text-decoration: none;
    color: rgba(0, 0, 0, 0.7) !important;
	font-weight: 0;
}

.table-body-container {
	padding: 20px 23px 0px 16px;
	border: 1px solid rgba(204, 204, 204, 0.5);
	padding-left: 6px;
	padding-right: 5px;
}

.table-body-container table thead tr > th {
	font-family: "Poppins" !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: var(--secondary-color) !important;
	padding: 13px;
}

.table-body-container table tbody tr td {
	font-family: "Poppins";
	font-size: 16px !important;
	line-height: 24px !important;
	color: var(--nonaryTextColor) !important;
	padding-top: 14px !important;
	padding-bottom: 12px !important;
}

.en-ligne,
.pas-encore-lance,
.termine {
	font-family: Roboto;
	font-weight: 500 !important;
	font-size: 12px !important;
	line-height: 140% !important;
	letter-spacing: -0.3px;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 2px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 12px;

	border-radius: 10px;
}

.en-ligne {
	width: 65px;
	background: #e9f3f4 !important;
	color: var(--secondary-color) !important;
}

.pas-encore-lance {
	width: 112px;
	font-weight: bold !important;
	background: #e8f5e9 !important;
	color: #4caf50 !important;
}

.termine {
	width: 67px;
	font-weight: bold !important;
	background: #fbede7 !important;
	color: #fe976b !important;
}

.child-table-container {
	background-color: white;
}
