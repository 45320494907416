@import url('../../../colors.css');
body{
  padding: 0;
  margin: 0 !important;
}

html{
  width: 100%;
  height: 100%;
}
.component-front-login {
  padding: 100px;
  background: rgba(245, 245, 245, 255);
  border-radius: 10px;
}

.connexion{
  height: 100vh;
  background: var(--septanaryColor);
}

.divLogo{
  background: var(--secondary-color);
  border-radius: 14px;
  /* height: 90vh; */
}

.divLogo p{
  color: var(--primaryColor);
}

.welcome-aem-front {
  width: 100%;
  height: 100%;
  background: var(--secondary-color);
  border-radius: 10px;
}

.formLogin-aem-front {
  width: 100%;
  height: 100%;
  background: var(--primaryColor);
  border-radius: 10px;
}
.title-welcome {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: 101.7%;
  letter-spacing: 0.315em;
  color: var(--primaryColor);
  font-size: 18px;
}

.bienvenue-style {
  padding: 46px;
}

.text-welcome-aem {
  font-family: "Poppins";
  font-style: bold;
  font-weight: 500;
  font-size: 54px;
  line-height: 101.7%;
  /* display: flex;
      align-items: center; */
  letter-spacing: 0.315em;
  color: var(--primaryColor);
}
.paragraphe-aem {
  width: 369px;
  height: 42px;
  left: 200px;
  top: 401px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130.2%;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--primaryColor);
}
.title-inscrivez-vous {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  color: var(--primarytextColor);
}

.clean-border {
  border-left: 0px solid transparent !important;
  border-right: 0px solid transparent !important;
  border-top: 0px solid transparent !important;
  border-bottom: 1px solid var(--primaryBoxShadowColor) !important;
}

/* .border-input {
  width: 22px;
  height: 22px;
  border: 1px solid transparent var(--denaryTextColor) !important;
  box-sizing: border-box;
  border-radius: 5px;
} */

/* .btn-creer-compte {
  height: 50px !important;
  width: 200px !important;
  background: var(--denaryTextColor);
  color: var(--primaryColor);
  background-color: var(--denaryTextColor) !important;
  border-color: var(--denaryTextColor) !important;
  border-radius: 5px;
} */

.inscrivez-vous {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: var(--nonaryTextColor);
}

.position-formLogin {
  position: relative;
}

.position-icon-identifiant {
  position: absolute;
}
/* .mdpOublie-form-login {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  color: #206285;
  text-decoration: none !important;
} */
/* .mdpOublie-form-login:hover {
  text-decoration: none !important;
  color: var(--nonaryTextColor) !important;
} */
/* .mdpOublie-form-login:hover {
  color: var(--denaryTextColor) !important;
  text-decoration: none !important;
} */

/* .color-icon {
  color: #848484;
} */
.position-forget {
  margin-left: 385px;
}

.decoration-ici:hover {
  text-decoration: none !important;
  color: var(--nonaryTextColor) !important;
}

/* .color-part2 {
  text-decoration: none !important;
  color: var(--denaryTextColor) !important;
} */
.position-btn {
  text-align: center;
  width: 100%;
}
/* .facebook-social-style {
  background-color: var(--denaryTextColor);
  color: white;
  padding: 21px;
  height: 70px;
  width: 70px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50px;
} */
/* .facebook-social-style:hover {
  background-color: white;
  border: 1px solid var(--denaryTextColor);
  color: var(--denaryTextColor);
  padding: 21px;
  height: 70px;
  width: 70px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50px;
  transform: scale(0.8);
} */
.google-social-style {
  background-color: var(--denaryTextColor);
  color: var(--primaryColor);
  padding: 21px;
  height: 70px;
  width: 70px;
  border-radius: 50px;
  margin: 0 auto;
  text-align: center;
  /* padding: 0 auto;
    padding-left: 29px;
    padding-top: 27px; */
  cursor: pointer;
}

/* .google-social-style:hover {
  background-color: white;
  border: 1px solid var(--denaryTextColor);
  color: var(--denaryTextColor);
  padding: 21px;
  height: 70px;
  width: 70px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50px;
  /* padding: 0 auto;
    padding-left: 29px;
    padding-top: 27px; */
  /* cursor: pointer; */
  /* transform: scale(0.8); */


/* .twitter-social-style {
  background-color: var(--denaryTextColor);
  color: white;
  padding: 21px;
  height: 70px;
  width: 70px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50px;
  /* padding: 0 auto;
    padding-left: 29px;
    padding-top: 27px; */
  /* cursor: pointer; */

/* .twitter-social-style:hover {
  background-color: white;
  border: 1px solid var(--denaryTextColor);
  color: var(--denaryTextColor);
  padding: 21px;
  height: 70px;
  width: 70px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50px;
  /* padding: 0 auto;
    padding-left: 29px;
    padding-top: 27px; */
  /* cursor: pointer; */
  /* transform: scale(0.8); */

.in-progress-btn {
  width: 300px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  /* line-height: 21px; */
  /* letter-spacing: -0.025em; */
  color: var(--primaryColor);
  background: var(--denaryTextColor);
  box-shadow: 0px 4px 20px var(--quinaryBoxShadowColor);
  border-radius: 10px;
  padding: 1rem;
  white-space: nowrap;
}

.auth-form-container {
  background: var(--primaryColor);
  border-radius: 14px;
  height: 90vh;
}

.auth-form-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 60px;
}
.input-group.auth-form-group {
  position: relative;
  margin-bottom: 23px;
}
.input-group.auth-form-group .input-group-prepend {
  position: absolute;
  z-index: 999;
  top: 0%;
  left: 1%;
  background: transparent;
}
.input-with-icon {
  padding: 20px 45px;
  border: none;
}
.cv-input-with-icon {
  padding: 20px 31px !important;
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid var(--septanaryBorderColor);
}
.connexion .form-control{
  border: none;
  border-bottom: 1px solid var(--septanaryBorderColor);
  border-radius: 0;
  outline: none;
}

.connexion .form-control:focus{
  outline: none !important;
  box-shadow: none !important;
}



.span-input-icon {
  background: transparent !important;
  border: none !important;
  padding-top: 8px;
}
.auth-icon{
  width: 18px;
}
.mdpOublie{
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
}
.auth-submit-btn-container {
  margin-top: 30px;
}
.auth-submit-btn {
  background: var(--secondary-color);
  border-color: none;
  width: 50% !important;
  padding: 10px !important;
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 17px !important;
  text-transform: uppercase !important;
}
.auth-submit-btn2 {
  background: var(--denaryTextColor) !important;
  border-color: var(--denaryTextColor) !important;
  width: 50% !important;
  padding: 10px !important;
  font-family: DM Sans !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 13px !important;
  line-height: 17px !important;
  text-transform: uppercase !important;
}
.auth-submit-btn:hover {
  background: transparent !important;
  color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color);
}
.auth-submit-btn2:hover {
  background: transparent !important;
  color: var(--secondary-color) !important;
}
.other-auth-action-row {
  margin-top: 20px;
}
.auth-alternative-action-container {
  text-align: center;
}
.auth-action-textual {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
}

.auth-other-action-text-container {
	display: flex;
	justify-content: center;
  position: relative;
}

.auth-other-action-text {
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	text-align: center;
  color: var(--octanarioTextColor);
}

.auth-other-action-text::before, .auth-other-action-text::after {
  content: "";
  display: block;
  width: 45% !important;
  height: 1px !important;
  background-color: var(--tertiaryColor);
  position: absolute;
  top: 10px;
  padding: 1px;
}
.auth-other-action-text::before {
  left: 0;
}
.auth-other-action-text::after {
  right: 0;
}

.auth-other-links-subscribe {
	margin-top: 2rem;
	display: flex;
	justify-content: center;
	gap: 15%;
}

.auth-other-link, .auth-social-btn {
	list-style: none;
  background: var(--quaternary-color);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.titreConnexion{
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  color: var(--primarytextColor);
}
/* .textConnectez{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: #6B707B;
} */
.positionImageBleu{
  margin-left: 65%;
  margin-top: 38%;
}
.positionImageBleuInsc{
  margin-left: 18%;
  margin-top: 46%;
}
/* .labelAuth{
  color: #0A2141;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
} */
/* .span-icon-input{
  padding-top: 23px;
} */