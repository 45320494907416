.projets-en-cours {
	padding-bottom: 10rem;
}
.activite-table-title {
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 22px;
	line-height: 30px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	margin-bottom: 20px;
}

.activite-table-title-no-finish {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	padding: 0%;
}

.activite table thead tr > th {
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: #115c67 !important;
	padding: 13px;
}

.activite table tbody tr > td {
	font-family: Poppins;
	font-style: normal !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: gray !important;
	padding-top: 14px !important;
	padding-bottom: 12px !important;
}

.projets-en-cours h4 {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}

.activite-table-button {
	background: var(--primaryColor);
	border: 1px solid #115c67;
	box-sizing: border-box;
	border-radius: 5px;
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.025em;
	color: #115c67;
	height: 30px;
	margin: 0;
}

.activite-table-button-detail {
	width: 90px;
}

.activite-table-button-detail {
	width: 63px;
}

.solde h3 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 42px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}

.solde h4,
.statistiques h4 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	color: #115c67;
}

.solde h6,
.statistiques h6 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal !important;
	font-size: 16px;
	line-height: 21px;
	color: rgba(0, 0, 0, 0.5);
}

.soldeContent,
.statistiques-prets-content,
.statistiques-gains-content {
	width: 220px !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	text-align: center;
}
.statistiques-gains {
	width: 220px;
}
.projets-en-cours-button {
	padding: 2px 5px;
	border: 1px solid #115c67;
	box-sizing: border-box;
	border-radius: 5px;
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.025em;
	color: #115c67;
	text-align: center !important;
	cursor: pointer;
	background-color: transparent;
}

.projets-en-cours-button:hover {
	text-decoration: none;
}

.projets-en-cours-button-close {
	color: #115c67;
	text-align: center !important;
	background-color: transparent;
}

.modal-activite-container {
	width: 50% !important;
	top: 25% !important;
	left: 25% !important;
}

.activite-modal-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 25px;
	line-height: 30px;
	text-align: center !important;
	color: var(--primarytextColor);
}

.form-activite-modal-input-group {
	display: flex;
	justify-content: center;
	margin: 3rem 0;
}
.form-activite-modal-input {
	border: none;
	border-bottom: 1px solid #e0e0e0;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.5);
	padding: 0;
}

.button-modal-activite-security,
.button-modal-activite-security:hover {
	background: #115c67;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: var(--primaryColor);
	padding: 1.3rem 3.5rem;
	border-radius: 8px;
	text-decoration: none;
}

.activite-tabs-container {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.activite-tab {
	font-family: Poppins;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
	color: var(--primarytextColor);
	opacity: 0.5;
	list-style: none;
	cursor: pointer;
}

.activite-tab.active {
	opacity: 1 !important;
	color: var(--primarytextColor) !important;
}

.project-img {
	width: 90%;
}

.project-text {
	width: 120%;
}

.project-finish-title {
	font-family: Poppins;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 28px;
}


.project-stat {
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: -0.025em;
	color: #115c67;
}
.project-statis {
	font-family: DM Sans;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px;
}

.color-pri {
	color: #115c67;
}

.color-sec {
	color: #999CA9;
}
.title-block {
	background: #E9F3F4 !important;
	padding: 3rem 0;
}

.title-block h1 {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 25px;
	text-align: center;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.7);
	margin: 0;
}
@media screen and (max-width: 767px) {
	.modal-activite-container {
		width: 90% !important;
	}
	.activite-tabs-container {
		display: flex;
		align-items: flex-start;
		flex-direction: column !important;
	}
	.project-img {
		width: 100% !important;
	}
	
	.project-text {
		width: 100% !important;
	}
}

@media screen and (max-width: 1100px) {
	.modal-activite-container {
		width: 70% !important;
	}
}
