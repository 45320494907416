.banner-investment {
	background-image: url("../../../assets/images/investment.png");
	width: 100%;
	position: relative;
	height: 650px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
}
.banner-investment-title {
	font-family: Poppins;
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
	line-height: 78px;
	color: var(--primaryColor);
}

.banner-investment-text {
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 27px;
    color: var(--primaryColor);
}

.funding-risques-item-row {
	display: flex;
	align-items: center;

}