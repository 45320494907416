.child-table-container {
	background-color: white;
}

.name-email-group span {
	display: block;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
}

.name-email-group span.name {
	color: #2d3748;
}

.name-email-group span.email {
	color: #718096;
}

.table-actions-container {
    display: flex;
    align-items: center;
}

.cell-style {
    display: flex ;
    align-items: center;
}

.close {
	width: auto !important;
	height: auto !important;
}

.easypm-modal-header {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center
}

.crud-form-control {
    padding: 22px !important;
    border-radius: 10px !important;
}

