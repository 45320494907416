.header {
	background: var(--primaryColor);
}

.content {
	padding: 16px;
}

.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1080;
	left: 0;
	right: 0;
}

.sticky + .content {
	padding-top: 102px;
}

.component-front-header {
	background: var(--primaryColor);

	height: 85px;
}
.link-top-header {
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: var(--primaryColor)cc;
}
.link-top-header:hover {
	text-decoration: none;
	color: var(--primaryColor);
}

.component-front-header .nav-link {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 17px;
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.9);
	padding: 10px 15px;
	border-bottom: 2px solid transparent;
	white-space: nowrap;
}

.component-front-header .nav-link:hover,
.nav-link:focus {
	border-bottom: 2px solid #1955A8;
}
.component-front-header .nav-link.active {
	font-family: "DM Sans";
	font-weight: 700;
	border-bottom: 2px solid #1955A8;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 0.291667px;
	color: #1955A8 !important;
}

.dropdown-header {
	position: relative;
	display: inline-block;
}

.dropdown-content-header {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	padding: 12px 16px;
	z-index: 1;
	right: 0;
	top: 3.5rem;
}

.dropdown-header:hover .dropdown-content-header {
	display: block;
}
.dropdown-icon-top-header {
	width: 28px;
	height: 28px;
	color: #787878;
	background-color: var(--primaryColor);
	border-radius: 50%;
}
.dropdown-icon-top-header svg {
	width: 18px;
}
.link-top-header.dropdown-toggle::after {
	position: absolute;
	top: 70%;
	right: -14px;
}

.link-header-login {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: var(--primaryColor);
	background: #1955A8;
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 10px;
}
.link-header-login:hover {
	text-decoration: none;
	color: #115c67;
	background-color: transparent;
	border: 1px solid #115c67;
}
.link-header-register {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #115c67;
	background: var(--primaryColor);
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 10px;
	border: 1px solid #115c67;
}
.link-header-register:hover {
	background-color: #115c67;
	color: var(--primaryColor);
	text-decoration: none;
}
.header-scrolling-container {
	background-color: var(--primaryColor);
	z-index: 9999999;
	box-shadow: 0px 8px 15px rgb(0 0 0 / 5%);
}

.connexion-dropdown .dropdown-menu {
	right: 0;
	left: auto;
	top: 33px !important;
	min-width: 13rem;
	padding: 0.5rem 21px;
	margin: 0.125rem 0 0;
}
.connexion-dropdown .dropdown-toggle {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 22px;
	color: #115c67;
}
.connexion-dropdown .dropdown-toggle:hover,
.connexion-dropdown .dropdown-toggle:focus {
	text-decoration: none;
	color: #115c67;
}
.connexion-dropdown .icon {
	width: 35px;
	height: 35px;
	border-radius: 8px;
	border: 1.5px solid var(--primaryColor);
	background: #e3e3e3;
}
.connexion-dropdown .dropdown-item {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 12px;
	padding: 1rem;
	color: var(--primarytextColor);
}

.connexion-dropdown .dropdown-item .icon {
	border: 1.5px solid #130f40;
}
.no-bg.active {
	background-color: inherit !important;
}
.no-bg:hover {
	background-color: #115c67 !important;
	color: var(--primaryColor);
}
.logo-firsty {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 23px;
	line-height: 30px;
	color: var(--primarytextColor);
}
.logo-secondy {
	color: #115c67;
}
.link, .link:hover {
	font-family: Poppins;
	font-style: normal;
	font-size: 18px;
	line-height: 27px;
    color: black;
    text-decoration: none;
}
