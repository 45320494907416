.detail-projet-en-cours-de-campagne {
	background-color: var(--primaryColor);
	padding: 2rem;
}

.detail-projet-en-cours-de-campagne-card {
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
}
.detail-projet-en-cours-de-campagne-card-info {
	width: 40%;
	display: flex;
	align-items: center;
	margin-right: 5%;
}

.detail-projet-en-cours-de-campagne-card-date {
	width: 25%;
	margin-right: 5%;
	display: flex;
	justify-content: center;
}

.detail-projet-en-cours-de-campagne-card-links {
	width: 25%;
	display: flex;
	justify-content: center;
}

.detail-projet-en-cours-de-campagne-card-img img {
	width: 97px;
	height: 89px;
	border-radius: 8px;
	margin-right: 1rem;
}

.detail-projet-en-cours-de-campagne-card-date h6 {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.5);
}

.detail-projet-en-cours-de-campagne-card-text h1 {
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.8);
}

.detail-projet-en-cours-de-campagne-card-body {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 150.7%;
	letter-spacing: -0.025em;
	color: #737373;
	text-align: justify;
}

.detail-projet-en-cours-de-campagne-card-link {
	background: #115c67;
	border-radius: 10px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
    color: var(--primaryColor);
    padding: 1.5rem 2rem;
}

.detail-projet-en-cours-de-campagne-card-link:hover {
    color: var(--primaryColor);
    text-decoration: none;
}

@media screen and (min-width:992px) and (max-width: 1150px) {
	.detail-projet-en-cours-de-campagne-card-info {
		width: 45%;
		display: flex;
		align-items: center;
		margin-right: 3%;
	}
	
	.detail-projet-en-cours-de-campagne-card-date {
		width: 20%;
		margin-right: 2%;
		display: flex;
		justify-content: center;
	}
	
	.detail-projet-en-cours-de-campagne-card-links {
		width: 25%;
		display: flex;
		justify-content: center;
	}
	.detail-projet-en-cours-de-campagne-card-link {
		font-size: 12px;
		line-height: 18px;
	}
	.detail-projet-en-cours-de-campagne-card-date h6 {
		font-size: 11px;
		line-height: 20px;
		letter-spacing: -0.025em;
		color: rgba(0, 0, 0, 0.5);
	}
}

@media screen and (max-width: 767px) {
	.detail-projet-en-cours-de-campagne-card {
		display: flex;
		flex-direction: column;
	}
	.detail-projet-en-cours-de-campagne-card-info {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.detail-projet-en-cours-de-campagne-card-date {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	
	.detail-projet-en-cours-de-campagne-card-links {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.detail-projet-en-cours-de-campagne-card-img{
		width: 100%;
		margin-bottom: 5px;
	}

	.detail-projet-en-cours-de-campagne-card-img img {
		width: 100%;
		height: 180px;
		border-radius: 8px;
	}

	.detail-projet-en-cours-de-campagne-card-link {
		width: 90%;
		text-align: center;
	}
	.detail-projet-en-cours-de-campagne-card-date {
		width: 90%;
		display: flex;
		justify-content: center;
		margin-bottom: 5px;
	}	
}