@import url('../../../colors.css');
.component-front-home {
	background-color: var(--primaryColor) !important;
	box-sizing: border-box;
}
.content-view-start {
	position: relative;
}
.color-span {
	color: var(--secondary-color);
}
.secondyLogo {
	color: var(--secondary-color);
}
.titre {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;
	margin-top: 8rem;
	/* or 45px */
	margin-left: 18rem;
	color: var(--quinarioTextColor);
}
.rowBack {
	background: var(--tertiaryColor);
}
.margetitre {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;

	/* or 45px */

	color: var(--quinarioTextColor);
}
/* .ips {
  position: absolute;
  left: 300px;
  height: 500px;
  top: 300px;
  z-index: 199;
} */
/* .marginhome {
  margin-bottom: 150px;
} */
/* .titlepatrimoine {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 37px;
    line-height: 55px;
    color: var(--primarytextColor);
}
.texte-patrimoine {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;

  /* or 45px */

/* color: var(--secondaryTextColor);
    color: var(--secondaryTextColor);
} */ */
.titre-image-illustration {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 37px;
	line-height: 55px;
	color: var(--primarytextColor);
}
.texte-image-illustration {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--quinaryTextColor);
}
/* .imgpatrimoine {
  margin-top: -110px;
} */
/* .bloc-div-imgRight {
    margin-top: 4rem;
} */
.topimg {
	position: absolute;
	right: 51px;
	top: -34px;
	width: 100px;
	background: var(--primaryColor);
	/* box-shadow: 0px 13px 34px rgba(0, 0, 0, 0.15); */
	border-radius: 20px;
	padding: 30px;
}
.topimg2 {
	position: absolute;
	left: 55px;
	top: -48px;
	width: 100px;
	background: var(--primaryColor);
	/* box-shadow: 0px 13px 34px rgba(0, 0, 0, 0.15); */
	border-radius: 20px;
	padding: 30px;
}
.newleter {
	background: var(--tertiaryColor);
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.imgnewleter {
	/* opacity: 0.2; */
	padding: 15px 0;
}
.titrenewleter {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 33.203px;
	line-height: 44px;
	/* color: rgba(20, 27, 45, 0.7); */
}
.texte-newleter {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 21.25px;
	line-height: 28px;
	/* color: #141b2d; */
}
.inputnewleter {
	background: var(--primaryColor);
	border: 1px solid var(--primaryBorderColor);
	box-sizing: border-box;
	box-shadow: inset 0px 1px 5px var(--primaryBoxShadowColor);
	border-radius: 4px;
	height: 48px;
}
.btn-newleter {
	border: 2px solid var(--secondary-color);
	box-sizing: border-box;
	border-radius: 4px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 29px;
	color: var(--secondary-color);
}
.titrepartenaire {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.505em;
	/* color: #5c6574; */
}
.textepartenaire {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 21px;
	color: var(--secondaryTextColor);
}
.txtsurlignepartenaire {
	color: var(--secondary-color);
}
.imgpartenaire {
	width: 100%;
	padding: 50px;
}
.imgpartenaireparent {
	background: var(--primaryColor);
	/* box-shadow: 0px 0px 0px 1px rgba(51, 68, 99, 0.16); */
	border-radius: 5px;
	margin-bottom: 60px;
	box-sizing: border-box;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.titrefaq {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;
	margin: auto;
	color: var(--secondaryTextColor);
	width: 100px;
}
.titrefaqquestion {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	letter-spacing: -0.025em;
	/* color: #010101; */
}
.faqtexte {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	color: var(--quaterioTextColor);
}
.faqtextecontent {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	text-align: justify;
	color: var(--quaterioTextColor);
}
.parag {
	text-align: center;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 140%;
	color: var(--secondaryTextColor);
}
.cardBack {
	background: var(--primaryColor);
	box-shadow: 0px 4px 43px var(--octonaryBoxShadowColor);
	padding: 2rem;
}
/* .second-nav-link {
  padding-top: 5rem;
padding-left: 9rem;
} */

.color-boutton {
  color: var(--primaryColor);
  /* bleu */
  background: var(--secondary-color);
  border-radius: 4px;
}
.btn-theme-financer {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 175%;
	text-align: center;
	color: var(--primaryColor);
	background: var(--secondary-color);
	border-radius: 4px;
	padding: 1rem;
}

.funding-couverture-textual-container,
.funding-couverture-textual-container p {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 25px;
	color: var(--secondaryTextColor);
}
.test-link-btn {
	background: var(--secondary-color) !important;
	border-radius: 4px !important;
	border-color: var(--secondary-color) !important;
	font-family: DM Sans !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 175% !important;
	animation: wiggle 2s linear infinite;
	left: calc(50% - 3em);
	top: calc(50% - 2em);
	transform-origin: 50% 5em;
}

/* Keyframes */
@keyframes wiggle {
	0%, 7% {
	  transform: rotateZ(0);
	}
	15% {
	  transform: rotateZ(-15deg);
	}
	20% {
	  transform: rotateZ(10deg);
	}
	25% {
	  transform: rotateZ(-10deg);
	}
	30% {
	  transform: rotateZ(6deg);
	}
	35% {
	  transform: rotateZ(-4deg);
	}
	40%, 100% {
	  transform: rotateZ(0);
	}
}

.test-link-btn:hover {
	background: transparent !important;
	color: var(--secondary-color) !important;
}
.funding-couverture-btn-container {
	margin-top: 30px;
}
.how-funding-section-title-container {
	margin-top: 5%;
	margin-bottom: 5%;
}

.how-funding-section-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 32px;
	text-align: center;
	color: var(--secondary-color);
}
.how-funding-section-label {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 25px;
	text-align: center;
	display: block;
	color: var(--secondaryTextColor);
}
.funding-process-row {
	display: flex;
	align-items: center;
}
.funding-process-item-col {
	background: var(--primaryColor);
	box-shadow: 0px 4px 43px var(--octonaryBoxShadowColor);
	margin-bottom: 15px;
	padding: 15px;
}
.funding-process-item-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 17px;
	line-height: 25px;
	color: var(--secondary-color);
	margin-left: 0;
	margin-top: 0;
}
.funding-process-item-content,
.funding-process-item-content p {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 25px;
	color: var(--quinaryTextColor);
}
.funding-process-row .test-link-btn {
	margin-top: 45px !important;
	margin-bottom: 30px;
}
.funding-process-row .test-link-btn-container {
	text-align: center;
}
.how-funding-section-container {
	padding-bottom: 50px;
}

.funding-description-section-container {
	padding-top: 50px;
	padding-bottom: 50px;
}
.funding-description-item-row {
	display: flex;
	align-items: center;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-bottom: 6rem;
}
.funding-description-illustration-img {
	width: 100%;
	height: 45rem !important;
}
.funding-description-textual-content-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between !important;
	padding: 0 20px;
}
.funding-description-textual-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 32px;
	margin-top: 0;
	margin-bottom: 4rem;
}
.funding-description-textual {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 25px;
	color: var(--tertiaryTextColor);
	text-align: justify;
}
.funding-description-textual-content-container .test-link-btn-container {
	margin-top: 30px;
}
.funding-process-illustration-col{
	display: flex;
	align-items: flex-start;
}
.funding-process-illustration-col-content{
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* Lever des fonds container */

.raise-money-title-bulle-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 3.5rem;
}

.raise-money-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 40px;
	text-align: center;
	color: var(--secondary-color);
	margin-top: 9px;
}

.raise-money-step {
	margin-bottom: 2.5rem ;
}

.raise-money-step-title-bulle-container,
.raise-money-bulle-container,
.raise-money-step-bulle-container {
	display: flex;
	align-items: center;
}

.raise-money-step-title-bulle-container {
	margin-bottom: 0.8rem;
}	
.text-color {
    font-family: 'Poppins';
}
.text-color-financer {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: var(--secondaryTextColor);
}
.parag{
    font-family: 'DM Sans';
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    color: var(--secondaryTextColor);
}


.padd {
  padding-top: 2.2 rem !important;
}

.raise-money-img {
	width: 40px;
	height: 40px;
	margin-right: 10px;
}
.raise-money-step-container{
	margin: 3rem 0 5rem 0;
}
.raise-money-step-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 30px;
	color: var(--secondaryTextColor);
	margin-top: 9px;
}

.raise-money-step-bulle {
	width: 30px;
	height: 30px;
	margin-right: 10px;
}

.raise-money-step-text-container {
	padding-left: 4rem;
}

.raise-money-step-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	color: var(--secondaryTextColor);
	text-align: justify;
}

@media screen and (max-width: 767px) {
	.funding-process-illustration-col {
		margin-top: 3rem;
		justify-content: center;
	}
	.funding-description-textual-content-container{
		min-height: auto;
	}
}

