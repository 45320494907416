@import url('../../../colors.css');
@media screen and (max-width: 576px) {
  .titreContacter {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 17px !important;
    line-height: 36px;
    /* identical to box height */
    margin-left: 15%;
    margin-right: auto;
    text-align: center;

    /* bleu */

    color: var(--secondary-color);
  }
  .texteContacter1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px !important;
    line-height: 30px;
    text-align: center;
    color: var(--primarytextColor);
  }
  .textebanniereContacter {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.025em;
    text-align: justify;
    color: var(--septanarioTextColor);
    width: 290px !important;
    margin-left: 10% !important;
    margin-right: auto;
  }
  .banniereContacter {
    background: var(--tertiaryColor);
    height: 312px !important;
    padding-top: 60px;
    text-align: center;
    width: 100%;
  }
  .titrebanniereContacter{
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 21px !important;
    line-height: 30px;
    margin-left: 7% !important;
    margin-right: auto;
    letter-spacing: -0.025em;
    color: var(--quaternaryTextColor);
  }

  .inputform {
    width: 308px !important;
    height: 70px;
    border: 0.25 px solid var(--senaryBorderColor);
    box-sizing: border-box;
    filter: drop-shadow(0 px 4px 20px var(--octonaryBoxShadowColor));
    border-radius: 10px;
  }

  .textareaform {
    width: 308px !important;
    height: 229px;
    left: 875px;
    border: 0.25 px solid var(--senaryBorderColor);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 20px var(--octonaryBoxShadowColor));
    border-radius: 10px;
  }
}
@media (max-width: 768px) {
  .texteContacter1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: center !important;
    color: var(--primarytextColor);
  }
  .btnform {
    width: 109px;
    height: 57px;
    left: 1224px;
    float: left !important;
    margin-top: 40px;
    background: var(--secondary-color);
    border-radius: 10px;
  }
  .titrebanniereContacter {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 33px !important;
    line-height: 55px;
    margin-left: 25%;
    margin-right: auto;
    letter-spacing: -0.025em;
    color: var(--quaternaryTextColor);
  }
  .textebanniereContacter {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 18px;
    letter-spacing: -0.025em;
    text-align: justify;
    color: var(--septanarioTextColor);
    width: 440px;
    margin-left: 25% !important;
    margin-right: auto;
  }
}
@media (max-width: 1024px) {
  .inputform {
    width: 362px !important;
    height: 70 px;
    border: 0.25 px solid var(--senaryBorderColor);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 20px var(--octonaryBoxShadowColor));
    border-radius: 10px;
  }
}
.banniereContacter {
  background: var(--tertiaryColor);
  height: 200px;
  padding-top: 60px;
  text-align: center;
  width: 100%;
}
.titrebanniereContacter {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 55px;
  margin-right: auto;
  letter-spacing: -0.025em;
  color: var(--quaternaryTextColor);
}
.textebanniereContacter {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.025em;
  text-align: justify;
  color: var(--septanarioTextColor);
}
.titreContacter {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: var(--secondary-color);
}
.texteContacter1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--primarytextColor);
}
.texteContacter2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--primarytextColor);
}
.labelform {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--nonaryTextColor);
}
.main-contact {
  margin-top: 60px;
}
.inputform {
  width: 490px;
  height: 70px;
  border: 0.25px solid var(--senaryBorderColor);
  box-sizing: border-box;
  border-radius: 10px;
}
.textareaform {
  width: 490px;
  height: 229px;
  left: 875px;
  border: 0.25px solid var(--senaryBorderColor);
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 20px var(--octonaryBoxShadowColor));
  border-radius: 10px;
}
.btnform {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 60px;
  color: var(--primaryColor);
  width: 109px;
  height: 57px;
  margin-right: -120px;
  float: right;
  margin-top: 20px;
  background: var(--secondary-color);
  border-radius: 10px;
}
/* input {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: rgba(0, 0, 0, 0.5);
}
textarea {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: rgba(0, 0, 0, 0.5);
} */
