

.style-checkbox{
  border: 0.25px solid #115C67 !important;
  padding: 0px !important;
}

input[type='checkbox']{
  top: 0px !important;
  box-sizing: border-box;

}


.rs-uploader-trigger-btn {
    background: none !important;
    border: none !important;
    padding: 20px !important;
    border-radius: 0 !important;
}

.card-telecharger-photo{
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: var(--primaryColor);
}

.btn-enregistrer{
  width: 136px;
  height: 57px;
  margin-top: -100px;
  margin-bottom: 50px;
}

.title-verification{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 42px;
  text-align: center;
  color: var(--primarytextColor);
}


.contenu-card{
  width: 80%;
  margin: auto;
}


.boutton-div{
  margin: 0 20px;
}