.content {
	background-color: var(--primaryColor);
	height: 108px;
}

.actived {
	color: #115C67 !important;
}

.tabsTitle {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
    line-height: 25px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: #010101;
	text-transform: uppercase;
}

.navLink {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
}

.active {
	color: #115C67;
    text-decoration: none;
}

.navLink:hover, .navLink:focus {
    color: #115C67;
    text-decoration: none;
}

@media screen and (max-width: 767px){
    .content {
        height: 270px;
    }
}

@media screen and (max-width: 1024px) {
    .tabsTitle {
        font-size: 20px;
    }
}
