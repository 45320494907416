@import url('../../../../colors.css');

.dashboard-container {
	background-color: var(--quinaryColor);
	padding: 2rem;
}
.dashboard-card {
	background-color: var(--primaryColor);
	padding: 1.5rem 2rem;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 15px;
	width: 100%;
}

.dasboard-card-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 150%;
	color: var(--quinaryTextColor);
	margin: 0;
	margin-bottom: 7px;
}

.dasboard-card-montant {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 140%;
	color: var(--secondary-color);
	margin: 0;
}

.dasboard-card-img-container {
	background-color: var(--secondary-color);
	padding: 1rem;
	border-radius: 12px;
	color: var(--primaryColor);
}

.dashboard-chart-container {
	background: var(--primaryColor);
	box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
	border-radius: 15px;
	padding: 1.5rem 2rem;
	margin-top: 3rem;
}

.dashboard-chart-info-filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dashboard-chart-info-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	color: #2d3748;
	margin: 0;
	margin-bottom: 10px;
}

.dashboard-chart-info-stat {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #115c67;
	margin: 0;
}

.dashboard-chart-filter {
    background: var(--primaryColor);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
    height: 45px;
    position: relative;
}

.dashboard-chart-filter-select {
    background: var(--primaryColor);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;
    color: #252733;
    height: 100%;
    padding: 0 12rem 0 3rem;
    border: none;
    outline: none;
    cursor: pointer;
}

.dashboard-chart-filter-icon {
    position: absolute;
    right: 3px;
    top: 10px;
    color: #115c67;
}
