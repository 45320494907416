@import url('../../../colors.css');
.banner-liste-projet {
	background: var(--tertiaryColor);
	padding: 10rem;
}
.component-projet-item {
	background: var(--primaryColor);
	border-radius: 15px;
	font-family: "Raleway", sans-serif;
	width: 90%;
}
.text-sub-title {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: -0.025em;
	color: var(--septenaryTextColor);
}
/* .card-style {
	background-color: #f2f2f2;
	box-shadow: 0px 4px 10px var(--primaryBoxShadowColor);
} */
.component-projet-item .card-title {
	color: var(--primaryTextColor) !important;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
}
.titre-banner-liste-projet {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 32px !important;
	line-height: 140% !important;
	color: var(--secondaryTextColor);
}
.sub-title-liste-projet {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 31px;
	letter-spacing: 0.5em;
	color: var(--primaryTextColor);
}
.text-pourcentage {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}
.sub-title-liste-projet-theme {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 31px;
	letter-spacing: 0.5em;
	color: var(--tertiaryColor);
}
.filter-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 33px;
	color: var(--primaryTextColor);
}

.text-progress-2 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 140%;
	color: var(--septenaryTextColor);
}
.liste-href a {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: var(--secondary-color);
}

.text-voir-plus {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: var(--secondary-color);
}
.text-voir-plus:hover {
	text-decoration: none;
	color: var(--secondary-color);
}
.pagination > li > a,
.pagination > li > span {
	position: relative !important;
	padding: 4px 10px;
	margin-left: -1px;
	line-height: 1.42857143;
	color: var(--secondary-color) !important;
	text-decoration: none;
	background-color: var(--primaryColor);
	border: none !important;
}

.text-progress {
	color: var(--secondary-color);
}
.card-button {
	position: relative;
}
.text-amorcage {
	color: var(--duodenary);
}
.text-amorcage-bleu {
	color: var(--secondary-color);
}
.text-amorcage-verte {
	color: var(--undenaryTextColor);
}
.card-button-text {
	position: relative;
}
.dette-amorcage {
	top: 20px;
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.btn-financer-dette {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: var(--denaryTextColor);
	border: 1px solid var(--denaryTextColor);
}
.btn-financer-dette-bleu {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: var(--secondary-color);
	border: 1px solid var(--secondary-color);
}
/* .btn-financer-dette-verte {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: var(--undenaryTextColor);
	border: 1px solid var(--undenaryTextColor);
} */
/* .btn-financer-dette-bleu-clair {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: #56ccf2;
	border: 1px solid #56ccf2;
} */
.participant {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 140%;
	color: var(--secondary-color);
}
.date-text {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 140%;
	color: var(--septenaryTextColor);
}
.btn-financer-projet {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: #1955A8;
	border-radius: 10px;
	padding: 1rem 2rem 1rem 2rem;
	width: 100%;
}
.btn-financer-projet-red {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: var(--secondary-color);
	border-radius: 10px;
	padding: 1rem 2rem 1rem 2rem;
	width: 100%;
}

/* .btn-financer-projet-red {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: rgba(254, 24, 24, 0.7);
	border-radius: 10px;
	padding: 1rem 2rem 1rem 2rem;
	width: 100%;
} */

.selectInput {
	height: 44px;
	border: 1px solid var(--secondaryBorderColor);
	box-sizing: border-box;
	border-radius: 10px;
	outline: none;
	background-color: var(--primaryColor);
	font-family: Poppins !important;
	font-style: normal;
	appearance: none;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: var(--primaryTextColor);
	cursor: pointer;
	width: 200px;
}

.selectInput option {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;

	color: var(--primarytextColor);
}

.filterLabel {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 33px;
	/* identical to box height */

	color: var(--primarytextColor);
}

.project-item-card-img-container {
	position: relative;
}
.project-funding-type-label-container {
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
    padding: 15px;
}
.project-funding-type {
	padding: 3px 10px;
	text-transform: capitalize;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 21px;
	color: var(--primaryColor);
}

.project-funding-type.equity-item {
	border: 1px solid var();
	box-sizing: border-box;
	border-radius: 4px 0px;
	background: var(--denaryTextColor);
}
.project-funding-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 21px;
	text-transform: capitalize;
}
.project-funding-label.amorcage-item {
	color: var(--denaryTextColor);
}
.project-funded-pourcentage,
.project-funded-pourcentage p {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	color: var(--secondary-color) !important;
}
.project-funding-container,
.project-funding-container p {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	color: var(--secondary-color);
}
.project-dates-container,
.project-dates-container p {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 140%;
	color: var(--septenaryTextColor);
}
.project-funding-donor-counting,
.project-funding-donor-counting p {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 140%;
	color: var(--secondary-color);
}
.project-item-name {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 140%;
	color: var(--secondaryTextColor);
	padding: 0 15px;
}
.project-description-container {
    min-height: 100px;
}
.project-description-container,
.project-description-container p {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	text-align: justify;
	color: var(--quinaryTextColor);
	padding: 0 7px;
}
.fund-project-btn {
	background: var(--secondary-color) !important;
	border-radius: 4px !important;
	width: 90% !important;
	padding: 12px !important;
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 21px !important;
	color: var(--primaryColor) !important;
}

.disabled-btn{
	border-radius: 4px !important;
	width: 90% !important;
	padding: 12px !important;
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 21px !important;
	color: var(--primaryColor) !important;
}

/* .project-funding-type.don-item {
	border: 1px solid var(--undenaryTextColor);
	box-sizing: border-box;
	border-radius: 4px 0px;
	background: var(--undenaryTextColor);
} */

/* .project-funding-label.early-state-item {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: var(--undenaryTextColor);
} */

/* .start-notification-btn {
	background: rgba(254, 24, 24, 0.7);
	border-radius: 4px;
	width: 90%;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: var(--primaryColor);
	padding: 12px !important;
} */

.project-funding-type.dette-item {
	border: 1px solid var(--secondary-color);
	box-sizing: border-box;
	border-radius: 4px 0px;
	background: var(--secondary-color);
}

.project-funding-label.pre-seed-item {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: var(--secondary-color);
}
.project-item-container {
	margin-bottom: 1%;
}
/* .project-funding-type.love-money-item {
	border: 1px solid #56ccf2;
	box-sizing: border-box;
	border-radius: 4px 0px;
	background: #56ccf2;
} */
.project-item-card-body {
	background: var(--primaryColor);
	box-shadow: 0px 2px 4px var(--senaryBoxShadowColor);
}
.label-project-filter {
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	color: var(--nonaryTextColor);
}
